var aria = require('./lib/utils');


// Toggle groups
const creatCloseGroupEvent = (group) => {
  // Create the global event to close current open toggle panel
  return new Event('close-current-' + group +'.toggles', {"bubbles":true,});
}

// Close current open toggle header element
const togglesElem = document.querySelectorAll('[data-js-toggle-group]');
if (0 != togglesElem.length) {
  [].forEach.call(togglesElem, (elem) => {
    const toggleGroup = elem.dataset.jsToggleGroup.split(', ');
    toggleGroup.forEach((group) => {
      document.addEventListener("close-current-" + group +".toggles", (e) => {
        e.preventDefault();
        if(currentSubMenuOpened === elem.dataset.jsToggled) {
          closeMenuPanel(elem);
        }
      });
    });
  });
}

// Menu
// ====
const menu_conf = {
  links: 'data-js-menu-link',
  linkActive: 'is-active',
  subMenuOpen: "is-open",
  subMenus: 'data-js-menu-panel',
};


// Store the current open toggle
let currentSubMenuOpened = "";

const setCurrentSubMenu = (id) => {
  null != id ? currentSubMenuOpened = id : currentSubMenuOpened = "";
}

// Close a submenu
const closeMenuPanel = (panel) => {
  if ("" !== currentSubMenuOpened){
    // Get current opened panel
    const currentOpenPanel = document.querySelector('[' + menu_conf.subMenus + '="' + currentSubMenuOpened +'"]');
    currentOpenPanel.classList.remove(menu_conf.subMenuOpen);
    currentOpenPanel.setAttribute('aria-hidden', "true");
    const targetLink = document.querySelector('['+ menu_conf.links + '="' + currentSubMenuOpened + '"]');
    targetLink.setAttribute ("aria-expanded", "false");
    targetLink.classList.remove(menu_conf.linkActive);

    if (panel === currentOpenPanel) {
      targetLink.focus();
    }
    setCurrentSubMenu();
  }
}

// Open a submenu
const openMenuPanel = (panel, keyboard) => {

  // const toggleGroup = panel.dataset.jsToggleGroup;
  // panel.dispatchEvent(creatCloseGroupEvent(toggleGroup));
  closeMenuPanel(panel);

  // Adding style en aria to sub-menu
  panel = document.querySelector('[' + menu_conf.subMenus + '="' + panel +'"]');
  panel.classList.add(menu_conf.subMenuOpen);
  panel.setAttribute('aria-hidden', "false");

  // Set style end aria on related sub-menu link
  const targetLink = document.querySelector('['+ menu_conf.links + '="' + panel.dataset.jsMenuPanel+ '"]');
  targetLink.setAttribute("aria-expanded", "true");
  targetLink.classList.add(menu_conf.linkActive);

  // Set tabindex on links
  const currentLink =  document.querySelector('['+ menu_conf.links + '][tabindex="0"]');
  null != currentLink ? currentLink.setAttribute('tabindex', -1) : null ;
  targetLink.setAttribute('tabindex', 0);

  if (true === keyboard) {
    // Set focus on the first sub-menu link
    const firstLink = panel.querySelector("[role='menuitem']")
    firstLink.focus();
  }

  setCurrentSubMenu(panel.dataset.jsMenuPanel);

}

// Event listener on menu links
const menuLinks = document.querySelectorAll('[' + menu_conf.links + ']');
if (0 != menuLinks.length) {
  [].forEach.call(menuLinks, function(link) {
    const targetPanelName = link.dataset.jsMenuLink;
    // const targetPanel =  document.querySelector('[' + menu_conf.subMenus + '="' + targetPanelName + '"]');
    // Click event
    link.addEventListener("click", (e) => {
      e.preventDefault();
      // Si pas ouvert
      let event;
      if (!e.target.classList.contains(menu_conf.linkActive)) {
        event = 'open.' + targetPanelName;
      } else {
        event = 'close.' + targetPanelName;
      }
      const eventPanel = new CustomEvent('sub-menu', {
        "bubbles": true,
        detail: {event: event, keyboard: false, },
      });
      link.dispatchEvent(eventPanel);
    });
    // Keyboard navigation
    link.addEventListener("keyup", (e) => {
      e.preventDefault();
      // gestion clavier au sein des sous menu
      var key = e.which || e.keyCode;
      var currentFocused = link;
      switch (key) {
        case aria.KeyCode.RIGHT:
          // Go to next link
          let nextElem = currentFocused.parentNode.nextElementSibling;
          if (nextElem != null) {
            nextElem.firstElementChild.focus();
          }
          break;
        case aria.KeyCode.LEFT:
          // Go to previous link
          let prevElem = currentFocused.parentNode.previousElementSibling;
          if (prevElem != null) {
            prevElem.firstElementChild.focus();
          }
          break;
        case aria.KeyCode.DOWN:
          // Open sub-menu
          const eventPanel = new CustomEvent('sub-menu', {
            "bubbles": true,
            detail: {event: 'open.' + targetPanelName, keyboard: true,},
          });
          link.dispatchEvent(eventPanel);
          break;

      }
    });
  });
}

// Event listener on sub-menu
const subMenus = document.querySelectorAll('[' + menu_conf.subMenus + ']');
if (0 != subMenus.length) {
  [].forEach.call(subMenus, (elem) => {
    elem.addEventListener("keyup", (e) => {
      e.preventDefault();
      // gestion clavier au sein des sous menu
      var key = e.which || e.keyCode;
      const currentFocused = elem.querySelector("[role='menuitem']:focus");

      switch (key) {
        case aria.KeyCode.ESC:
          // Escape key pressed => close submenu, set focus on link
          closeMenuPanel(elem);
          break;
        case aria.KeyCode.DOWN:
          // Go to next link
          let nextElem = currentFocused.parentNode.nextElementSibling;
          if (nextElem != null) {
            nextElem.firstElementChild.focus();
          }
          break;
        case aria.KeyCode.UP:
          // Go to previous link
          let prevElem = currentFocused.parentNode.previousElementSibling;
          if (prevElem != null) {
            prevElem.firstElementChild.focus();
          }
          break;
        case aria.KeyCode.RIGHT:
          // Go to previous link
          let nextMenuLink = elem.parentNode.nextElementSibling.querySelector('[' + menu_conf.links + ']');
          if (null != nextMenuLink) {
            closeMenuPanel(elem);
            nextMenuLink.focus();
          }
          break;
        case aria.KeyCode.LEFT:
          // Go to previous link
          let prevMenuLink = elem.parentNode.previousElementSibling.querySelector('[' + menu_conf.links + ']');
          if (null != prevMenuLink) {
            closeMenuPanel(elem);
            prevMenuLink.focus();
          }
          break;
      }
    });
  });
}



// Btn close panel
const closeGroupBtns = document.querySelectorAll('[data-js-menu-close]');
if (0 != closeGroupBtns.length) {
  [].forEach.call(closeGroupBtns, (elem) => {
    elem.addEventListener("click", (e) => {
      e.preventDefault();
      closeMenuPanel();
    });
  });
}


const openToggle = (toggleId) => {

  const toggleItem = document.querySelector('[data-js-toggled="' + toggleId +'"]');
  // Adding style en aria to sub-menu
  toggleItem.classList.add('is-open');
  toggleItem.setAttribute('aria-hidden', "false");
  const isOpenEvent = new CustomEvent('is-open.' + toggleId + '.toggle', {
    bubbles: true,
    detail: {
      event: 'is-open.' + toggleId + '.toggle',
    },
  });

  toggleItem.dispatchEvent(isOpenEvent);

  // Set style end aria on related sub-menu link
  const targetLink =  document.querySelector('[data-js-toggle="' + toggleId +'"]');
  targetLink.setAttribute("aria-expanded", "true");
  targetLink.classList.add('is-active');
}

const closeToggle = (toggleId) => {
  const toggleItem = document.querySelector('[data-js-toggled="' + toggleId +'"]');
  // Adding style en aria to sub-menu
  toggleItem.classList.remove('is-open');
  toggleItem.setAttribute('aria-hidden', "true");

  // Set style end aria on related sub-menu link
  const targetLink =  document.querySelector('[data-js-toggle="' + toggleId +'"]');
  targetLink.setAttribute("aria-expanded", "false");
  targetLink.classList.remove('is-active');
}

// Click btn Toggle
const toggleBtns = document.querySelectorAll('[data-js-toggle]');

if (0 != toggleBtns.length) {
  [].forEach.call(toggleBtns, (btn) => {
    btn.addEventListener("click", (e) => {
      e.preventDefault();
      const relatedTooglePanelId = btn.dataset.jsToggle;
      const relatedTooglePanel = document.querySelector('[data-js-toggled="' + relatedTooglePanelId +'"]');

      let event;
      if(!relatedTooglePanel.classList.contains("is-open")) {
        event = 'open.' + relatedTooglePanelId;
      } else {
        event = 'close.' + relatedTooglePanelId;
      }
      const eventPanel = new CustomEvent('toggle', {
        "bubbles": true,
        detail: {event: event, },
      });
      btn.dispatchEvent(eventPanel);
    });
  })
}

// Le parent qui contrôle les ouverture fermeture des trucs
const headerController = document.querySelector('[data-js-menu-ctrl]');
if (0 != headerController.length) {
  headerController.addEventListener('sub-menu', (e) => {
    e.preventDefault();

    const eventPart = e.detail.event.split('.');
    const event = eventPart[0];
    const target = eventPart[1];

    if (event === 'open') {
      closeToggle('searchBox');
      const a11yNav = e.detail.keyboard;
      openMenuPanel(target, a11yNav);
    } else if (event === 'close') {
      closeMenuPanel();
    }
  });
  headerController.addEventListener('toggle', (e) => {
    e.preventDefault();

    const eventPart = e.detail.event.split('.');
    const event = eventPart[0];
    const target = eventPart[1];

    if (event === 'open') {
      if (target === 'searchBox') {
        // Close submenu if open
        closeMenuPanel();
        // Close mobile menu if open
        closeToggle('menu');
      }
      if (target === 'menu') {
        // Close mobile menu if open
        closeToggle('searchBox');
      }
      // Open toggle
      openToggle(target);
    } else if (event === 'close') {
      closeToggle(target);
    }
  });
}

// Search box events
// =================

// Open search box
const searchBox = document.querySelector('[data-js-toggled="searchBox"]');

if (null !== searchBox && 0 !== searchBox.length) {
  searchBox.addEventListener("is-open.searchBox.toggle", (e) => {
    e.preventDefault();
    searchBox.querySelector('[type="search"]').focus();
  })
  // SearchBox keys
  searchBox.addEventListener("keyup", (e) => {
    e.preventDefault();
      // gestion clavier au sein des sous menu
      var key = e.which || e.keyCode;

      switch (key) {
      case aria.KeyCode.ESC:
        // Escape key pressed => close search box
        closeMenuPanel(elem);
        break;
      }
  })
}
